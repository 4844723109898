<template>
    <LiefengContent>
        <template #title>{{ "整体发布情况" }}</template>
        <template #toolsbarRight>
            <Select clearable v-model="monthValue" style="width: 200px" @on-change="changeMonthValue">
                <Option :value="'0'">{{ "按月" }}</Option>
                <Option :value="'1'">{{ "按年" }}</Option>
            </Select>
            <DatePicker v-model="years" type="year" placeholder="请选择年份,为空查找所有年份" style="width: 200px" clearable @on-change="selectColumnFrequency()" />
            <Select clearable filterable v-model="selectValue" style="width: 200px" @on-change="changeSelectList">
                <Option v-for="(item, index) in selectList" :value="item.orgCode" :key="index">{{ item.orgName }}</Option>
            </Select>
            <Select v-if="monthValue == '0'" clearable multiple max-tag-count="3" v-model="months" style="width: 200px" @on-change="changeSelectMonth">
                <Option v-for="(item, index) in monthsallList" :value="item" :key="index">{{ item + "月" }}</Option>
            </Select>
        </template>
        <template #contentArea>
            <div>
                <div class="chart" id="chart" @click="changeOpen"></div>
            </div>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3.vue"
import * as echarts from "echarts"
// import VeLine from "../portalSystem/components/VeLine"
export default {
    components: { LiefengContent },
    data() {
        return {
            years: "2024",
            dimensionId: "2",
            orgCode: "",
            months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            monthsallList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            // 筛选条件
            selectValue: "",
            selectList: [],

            monthList: [
                { value: "0", label: "月" },
                { value: "1", label: "年" },
            ],
            monthValue: "0",
        }
    },
    async created() {
        this.getThirdMonth()
        this.selectByDimensionId()
        await this.selectColumnFrequency()
    },
    methods: {
        changeMonthValue(val) {
            this.monthValue = val
            this.selectColumnFrequency()
        },
        changeOpen() {
            if (this.monthValue == "0") {
                // 按月
                window.sessionStorage.setItem("detailList", JSON.stringify(this.dataList.detail))
            } else {
                // 按年
                window.sessionStorage.setItem("detailList", JSON.stringify(this.dataList.month))
                window.sessionStorage.setItem("detailList2", JSON.stringify(this.dataList.detail))
            }

            this.$core.openLayerFrame(
                {
                    type: 2,
                    title: "明细",
                    content: `/page#/sendviewTable?monthValue=${this.monthValue}&year=${this.years && this.years != "" ? this.$core.formatDate(new Date(this.years), "yyyy年") : ""}`,
                    area: ["100%", "100%"],
                },
                () => {
                    window.sessionStorage.removeItem("detailList")
                    window.sessionStorage.removeItem("detailList2")
                }
            )
        },
        changeSelectMonth(val) {
            this.months = val
            this.selectColumnFrequency()
        },
        changeSelectList(val) {
            this.orgCode = val
            this.selectColumnFrequency()
        },
        drawChart(names, data) {
            let el = document.getElementById("chart")
            // 销毁图表
            echarts.dispose(el)
            // 初始化图表
            echarts.init(el).setOption({
                color:[
                    '#5087EC',
                    '#68BBC4',
                    '#58A55D',
                    '#F2BD42',
                    '#EE752E',
                    '#D95040',
                    '#84B7F9',
                    '#B8E6F2',
                    '#4095C2',
                    '#DAA67B',
                    '#A1DEED',
                    '#FCDAB2',
                ],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                title: {
                    left: "left",
                    text: "内容发布情况统计",
                },
                xAxis: {
                    type: "category",
                    data: names,
                },
                yAxis: {
                    type: "value",
                },
                series: data,
                legend: {
                    show: true,
                },
            })
        },
        selectByDimensionId() {
            this.$get("/orgzz/pc/organization/selectByDimensionId", {
                dimensionId: "2",
            }).then(res => {
                this.selectList = res.dataList
            })
        },

        async selectColumnFrequency() {
            if (this.monthValue == "0") {
                // 按月
                let res = await this.$get("/statistic/api/symanage/pc/orgInfoPublish/statMonthListView", {
                    years: this.years && this.years != "" ? this.$core.formatDate(new Date(this.years), "yyyy") : "",
                    dimensionId: this.dimensionId,
                    orgCode: this.orgCode,
                    months: this.months && this.months != "" ? this.months.join(",") : "",
                })
                if (res.code == 200) {
                    this.dataList = res.data
                    let x = []
                    let y = []
                    console.log("测试", )

                    Array.from(Object.values(res.data.charts), x => x).map((item, index) => {
                        y.push({
                            // name: index + 1 + "月",
                            name:Object.keys(res.data.charts)[index] + '月',
                            data: item,
                            type: "line",
                            smooth: true,
                            label: {
                                show: true, // 显示标签
                            },
                        })
                    })
                    this.$nextTick(() => {
                        // 绘制图表
                        this.drawChart(
                            (() => {
                                var list = []
                                for (var i = 0; i <= 30; i++) {
                                    list.push(i + 1)
                                }
                                return list
                            })(),
                            y
                        )
                    })
                } else {
                    this.$Message.error({
                        content: "获取失败",
                        background: true,
                    })
                    return
                }
            } else {
                // 按年
                let res = await this.$get("/statistic/api/symanage/pc/orgInfoPublish/statYearListView", {
                    years: this.years && this.years != "" ? this.$core.formatDate(new Date(this.years), "yyyy") : "",
                    dimensionId: this.dimensionId,
                    orgCode: this.orgCode,
                })
                this.dataList = res.data
                let x = []
                let y = []
                // Array.from(Object.values(res.data.month), x => x).map((item, index) => {
                y.push({
                    name: this.years && this.years != "" ? this.$core.formatDate(new Date(this.years), "yyyy年") : "",
                    data: Array.from(Object.values(res.data.month), x => x),
                    type: "line",
                    smooth: true,
                    label: {
                        show: true, // 显示标签
                    },
                })
                // })
                this.$nextTick(() => {
                    // 绘制图表
                    this.drawChart(
                        (() => {
                            var list = []
                            for (var i = 0; i <= 11; i++) {
                                list.push(i + 1 + "月")
                            }
                            return list
                        })(),
                        y
                    )
                })
            }
        },

        getThirdMonth() {
            //获取当前日期
            var myDate = new Date()
            this.years = String(myDate.getFullYear())
            this.months = [myDate.getMonth()+1]
        },
    },
}
</script>

<style lang="less" scoped>
.chart {
    margin: 10px 20px 0 20px;
    height: 500px;
}
</style>